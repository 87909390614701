import * as React from "react";
import { IReviewDetail } from "@types";
import parse from "html-react-parser";
import { Element } from "domhandler/lib/node";

import CustomerStoriesIndividualAsideContent from "./customerStoriesIndividualAsideContent/customerStoriesIndividualAsideContent";
import { handleHardcodeStyles } from "./helper";
import WistiaVideos from "../../Videos/WistiaVideos/WistiaVideos";

import styles from "./customerStoriesIndividualContent.module.scss";

const CustomerStoriesIndividualContent: React.FC<{
  reviewContent: string;
  reviewDetailList: IReviewDetail[];
}> = ({ reviewContent, reviewDetailList }) => {
  let widthScreen = 0;

  if (typeof window !== "undefined") {
    widthScreen = window.innerWidth;
  }

  const ReactElements = parse(reviewContent, {
    replace: node => {
      if (
        node.type === "tag" &&
        node instanceof Element &&
        node.attribs &&
        node.attribs.class === "wistia-video"
      ) {
        const videoId = node.attribs["wistia-id"];
        return <WistiaVideos videoId={videoId} autoplay />;
      }
      return node;
    },
  });

  let html = handleHardcodeStyles(reviewContent, widthScreen);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      html = handleHardcodeStyles(reviewContent, widthScreen);
    });

    return function cleanupListener() {
      window.removeEventListener("resize", () => {
        html = handleHardcodeStyles(reviewContent, widthScreen);
      });
    };
  }, [widthScreen]);
  return (
    <section className="full-width _pad-top--large pad-bottom--large">
      <div data-testid="Div:contentGridMain" className={styles.contentGridMain}>
        <div className={styles.contentGrid}>
          <div className={styles.reviewContent}>{ReactElements}</div>
        </div>
        <CustomerStoriesIndividualAsideContent
          CustomerStoryDetail={reviewDetailList}
        />
      </div>
    </section>
  );
};

export default CustomerStoriesIndividualContent;
