import * as React from "react";
import { graphql, PageProps } from "gatsby";
import CustomerStoriesIndividualHero from "../../components/customerStoriesIndividual/customerStoriesIndividualHero/customerStoriesIndividualHero";
import CustomerStoriesIndividualContent from "../../components/customerStoriesIndividual/customerStoriesIndividualContent/customerStoriesIndividualContent";
import CustomerStoriesIndividualCardsContainer from "../../containers/customerStoriesIndividualCardsContainer/customerStoriesIndividualCardsContainer";
import { formattedData } from "./helpers";
import { YoastSEOType } from "../../assets/Types";
import Seo from "gatsby-plugin-wpgraphql-seo";
import LayoutWithLargeFooterCta from "../../layouts/LayoutWithLargeFooterCta";
import { deIndexSeo } from "../../utils/deIndexSeo";

type CustomerStory = {
  customerStoriesC: any;
  title: string;
  seo: YoastSEOType;
};

type DataProps = {
  allWpCustomerStory: {
    nodes: CustomerStory[];
  };
  wpCustomerStory: CustomerStory;
};

const CustomerStoriesPage: React.FC<PageProps<DataProps>> = ({
  data,
  location,
}) => {
  const { wpCustomerStory } = data;

  const pageData = {
    ...data.wpCustomerStory,
    relatedCustomerStories: [...data.allWpCustomerStory.nodes],
  };

  const {
    customerStoriesIndividualHeroData,
    customerStoriesIndividualContentData,
    customerStoriesIndividualCardsContainerData,
  } = formattedData(pageData);
  return (
    <LayoutWithLargeFooterCta pathname={location.pathname}>
      {wpCustomerStory.seo && (
        <Seo post={deIndexSeo(wpCustomerStory as unknown as Queries.WpPage)} />
      )}
      <CustomerStoriesIndividualHero {...customerStoriesIndividualHeroData} />
      <CustomerStoriesIndividualContent
        {...customerStoriesIndividualContentData}
      />
      <CustomerStoriesIndividualCardsContainer
        {...customerStoriesIndividualCardsContainerData}
      />
    </LayoutWithLargeFooterCta>
  );
};

export default CustomerStoriesPage;

export const CUSTOMER_INDIVIDUAL_STORY_QUERY = graphql`
  query CustomeryIndividualStoryQuery(
    $relatedCustomerStories: [String]
    $id: String!
  ) {
    wpCustomerStory(id: { eq: $id }) {
      uri
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      customerStoriesC {
        reviewWysiwyg
        reviewSubcopy
        fieldGroupName
        reviewImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 512
                placeholder: BLURRED
                formats: [WEBP, JPG, AUTO]
              )
            }
          }
        }
        reviewDetailList {
          detailInfo
          detailLabel
        }
      }
      contentType {
        node {
          graphqlSingleName
        }
      }
    }
    allWpCustomerStory(filter: { id: { in: $relatedCustomerStories } }) {
      nodes {
        uri
        title
        customerStoriesC {
          reviewWysiwyg
          reviewSubcopy
          fieldGroupName
          reviewImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 512
                  placeholder: BLURRED
                  formats: [WEBP, JPG, AUTO]
                )
              }
            }
          }
          reviewDetailList {
            detailInfo
            detailLabel
          }
        }
        contentType {
          node {
            graphqlSingleName
          }
        }
      }
    }
  }
`;
