import * as React from "react";
import { IReviewDetail } from "@types";

import styles from "./customerStoriesIndividualAsideContent.module.scss";

const customerStoriesIndividualAsideContent: React.FC<{
  CustomerStoryDetail: IReviewDetail[];
}> = ({ CustomerStoryDetail }) => {
  const items = CustomerStoryDetail.map((item: IReviewDetail) => {
    return (
      <div key={item.detailInfo}>
        <p>
          <strong className="font-weight-500">{item.detailLabel}</strong>
        </p>
        <p>{item.detailInfo}</p>
      </div>
    );
  });

  return (
    <div data-testid="Div:asideContent" className={styles.asideContent}>
      <div>{items}</div>
    </div>
  );
};

export default customerStoriesIndividualAsideContent;
