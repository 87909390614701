import React, { useEffect } from "react";
import { VideoProps } from "../../../assets/Types/VideoProps.type";

const WistiaVideos: React.FC<VideoProps> = ({ videoId, autoplay = false }) => {
  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");
    script1.src = "https://fast.wistia.com/embed/medias/" + videoId + ".jsonp";
    script1.async = true;

    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, [videoId]);

  return (
    <div
      className={`wistia_embed wistia_async_${videoId} videoFoam=true allowtransparency="true" ${
        autoplay ? 'autoPlay="true"' : ""
      }`}
      style={{ position: "relative" }}
    />
  );
};

export default WistiaVideos;
