import * as React from "react";
import CustomerStoriesIndividualCards from "../../components/customerStoriesIndividual/customerStoriesIndividualCards/customerStoriesIndividualCard";

import styles from "./customerStoriesIndividualCardsContainer.module.scss";

const CustomerStoriesIndividualCardsContainer: React.FC<{
  CustomerStoriesCardsItem: any;
  CustomerStoriesCardsItemTitle: string;
}> = ({ CustomerStoriesCardsItem, CustomerStoriesCardsItemTitle }) => {
  const cardsList = CustomerStoriesCardsItem.slice(0, 4).map((story, index) => {
    return <CustomerStoriesIndividualCards card={story} key={index} />;
  });
  return (
    <section>
      <div className="full-width">
        <h2
          data-testid="h2:CustomerStoriesCardsItemTitle"
          className={styles.customerStoriesCardsTitle}
        >
          {CustomerStoriesCardsItemTitle}
        </h2>
        <div className={styles.relatedPostsContainer}>{cardsList}</div>
      </div>
    </section>
  );
};

export default CustomerStoriesIndividualCardsContainer;
