import { IPageContext } from "@types";

declare global {
  interface String {
    replaceAll: (str1: string, str2: string) => string;
  }
}

export const formattedData = pageContext => {
  const {
    customerStoriesC: {
      reviewDetailList,
      reviewImage,
      reviewSubcopy,
      reviewWysiwyg,
    },
    title,
    relatedCustomerStories,
  } = pageContext as IPageContext;

  let reviewContent = reviewWysiwyg;

  const objCallbackByTitles = {
    "Jill Shook": () => {
      const dirtyNewReviewContent = reviewWysiwyg.replaceAll(
        '<li style="font-weight: 400;"><b>',
        '<li style="font-weight: 500;">'
      );

      reviewContent = dirtyNewReviewContent.replaceAll(
        '</b><span style="font-weight: 400;">',
        '<span style="font-weight: 400;">'
      );
    },
    "Traci Lowenthal": () => {
      reviewContent = reviewContent.replaceAll(
        "position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;opacity:0;transition-delay:500ms",
        "position:absolute;top:0;left:0;width:100%;height:195.58px;object-fit:cover;object-position:center;opacity:0;transition-delay:500ms;margin-top:20px"
      );
      reviewContent = reviewContent.replaceAll(
        'style="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;opacity:1;transition:opacity 500ms"',
        'style="position:relative;width:100%;height:100%;max-height:198.58px;object-fit:cover;object-position:center;opacity:1;transition:opacity 500ms; margin-bottom: -6px"'
      );

      reviewContent = reviewContent.replaceAll(
        'style="width:100%;padding-bottom:30.8%"',
        'style="width:100%;padding-bottom:0"'
      );
    },
    "Tiffany McLain": () =>
      (reviewContent = reviewContent.replaceAll(
        'style="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;opacity:1;transition:opacity 500ms"',
        'style="position:absolute;top:0;left:0;width:100%;height:100%;max-height:301.391px;object-fit:cover;object-position:center;opacity:1;transition:opacity 500ms"'
      )),
  };

  const replaceCallback = objCallbackByTitles[title];
  if (replaceCallback) {
    replaceCallback();
  }

  reviewContent = reviewContent.replaceAll(
    '<div class="wistia_responsive_wrapper" style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">',
    '<div class="wistia_responsive_wrapper" style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;"><div style="position:absolute; background-color:#f1f1f1;filter:blur(1.5rem); width:100%; height:100%; z-index: -1"></div>'
  );

  return {
    customerStoriesIndividualHeroData: {
      reviewImage,
      reviewSubcopy,
      reviewTitle: title,
      reviewPageName: "Customer Story",
    },
    customerStoriesIndividualContentData: {
      reviewContent,
      reviewDetailList,
    },
    customerStoriesIndividualCardsContainerData: {
      CustomerStoriesCardsItemTitle: "Related Customer Stories You Might Like",
      CustomerStoriesCardsItem: relatedCustomerStories,
    },
  };
};
