export const handleHardcodeStyles = (reviewWysiwyg, width) => {
  let reviewContent = reviewWysiwyg;
  reviewContent = reviewContent.replaceAll(
    "<h3>",
    '<h3 style="margin-bottom: 16px">'
  );

  reviewContent = reviewContent.replaceAll(
    `style="height: 420px;"`,
    `style="height: 420px; background-color: #e5e5e5;"`
  );

  if (width < 768) {
    reviewContent = reviewContent.replaceAll(
      '<span aria-hidden="true" style="width:100%;padding-bottom:30.8%">',
      '<span aria-hidden="true" style="width:100%;">'
    );

    reviewContent = reviewContent.replaceAll(
      'style="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;object-position:center;opacity:1;transition:opacity 500ms"',
      'style="position:relative;width:100%;height:auto;object-fit:cover;object-position:center;opacity:1;transition:opacity 500ms; margin-bottom: -6px"'
    );
  }
  return reviewContent;
};
