import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Image } from "../../../assets/Types/Image.type";

import styles from "./customerStoriesIndividualHero.module.scss";

const customerStoriesIndividualHero: React.FC<{
  reviewImage: Image;
  reviewSubcopy: string;
  reviewTitle: string;
  reviewPageName: string;
}> = ({ reviewImage, reviewSubcopy, reviewTitle, reviewPageName }) => {
  const image = getImage(reviewImage?.localFile);
  return (
    <section className="pad-top--large-sans-header pad-bottom--intermediate">
      <div className="full-width">
        <div
          data-testid="Div:customerStoriesGrid"
          className={styles.customerStoriesGrid}
        >
          <div>
            <p className={styles.customerStoriesPageName}>{reviewPageName}</p>
            <h1 className={styles.customerStoriesTitle}>{reviewTitle}</h1>

            <p className={styles.customerStoriesText}>{reviewSubcopy}</p>
          </div>
          <div className={styles.customerStoriesImage}>
            <GatsbyImage image={image} alt={reviewTitle || ""} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default customerStoriesIndividualHero;
