import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import styles from "./customerStoriesIndividualCard.module.scss";

const customerStoriesIndividualCard: React.FC<{
  card: any;
}> = ({ card }) => {
  const {
    customerStoriesC: { reviewImage, reviewSubcopy },
    title,
    uri,
  }: any = card;
  const image = getImage(reviewImage?.localFile);
  return (
    <a
      data-testid="a:customerStoriesIndividualCard"
      href={uri}
      className={styles.customerStoriesIndividualCard}
    >
      <GatsbyImage
        className={styles.carImage}
        image={image}
        alt={title || ""}
      />

      <div data-testid="Div:cardText" className={styles.cardText}>
        <span className="hr hr--8"></span>
        <h4>{title}</h4>
        <span>{reviewSubcopy}</span>
      </div>
    </a>
  );
};

export default customerStoriesIndividualCard;
